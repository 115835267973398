export const Companies = [
  {
    img: "/image/company/cryptocom_logo.png",
  },
  {
    img: "/image/company/fsn_mark.png",
  },
  {
    img: "/image/company/zebec_mark.png",
  },
  {
    img: "/image/company/gil_tanked_logo.png",
  },
  {
    img: "/image/company/sf_mark.png",
  },
  {
    img: "/image/company/warp_mark.png",
  },
  {
    img: "/image/company/aldrin_mark.png",
  },
  {
    img: "/image/company/daib_mark.png",
  },
  {
    img: "/image/company/eden_mark.png",
  },
  {
    img: "/image/company/epik_mark.png",
  },
  {
    img: "/image/company/flucity_mark.png",
  },
  {
    img: "/image/company/fame_mark.png",
  },

  {
    img: "/image/company/seven_mark.png",
  },
  {
    img: "/image/company/eye_mark.png",
  },
  {
    img: "/image/company/mkyu_mark.png",
  },

  {
    img: "/image/company/aas_mark.png",
  },
  {
    img: "/image/company/lbio_mark2.png",
  },
  {
    img: "/image/company/dawa_logo.png",
  },
  {
    img: "/image/company/169_mark.png",
  },
];
